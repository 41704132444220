import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { endOfYear, startOfYear } from "date-fns";
import { useSnackbar } from "notistack";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MapIcon from "@mui/icons-material/Map";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where
} from "firebase/firestore";

import CancelReservation from "../components/Agenda/ReservationCanceled";
import ReservationICS from "../components/Agenda/ReservationICS";
import PaymentManagement from "../components/Agenda/ReservationPaymentManagement";
import PDFInvoiceGenerator from "../components/Agenda/ReservationPDF";
import ReservationTodo from "../components/Agenda/ReservationTodo";
import ReservationUpload from "../components/Agenda/ReservationUpload";
import ValidateReservation from "../components/Agenda/ReservationValidate";
import CustomerDetails from "../components/CustomerDetails";
import FilterMenu from "../components/FilterMenu";
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase";

const Agenda = () => {
  const { currentUser } = useAuth();
  const [openMenuId, setOpenMenuId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [reservations, setReservations] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    upcoming: false,
    confirmed: false,
    pending: false,
    unpaid: false,
    year: new Date().getFullYear()
  });

  const fetchAdditionalData = async reservation => {
    try {
      const catalogRef = doc(
        db,
        `users/${currentUser.uid}/catalog`,
        reservation.catalog_id
      );
      const catalogSnap = await getDoc(catalogRef);
      const productType = catalogSnap.exists()
        ? catalogSnap.data().type
        : t("agenda.unknownType");

      const clientRef = doc(
        db,
        `users/${currentUser.uid}/customers`,
        reservation.client_id
      );
      const clientSnap = await getDoc(clientRef);
      const clientName = clientSnap.exists()
        ? `${clientSnap.data().firstname}`
        : t("agenda.unknownClient");

      return {
        ...reservation,
        productType,
        clientName
      };
    } catch (error) {
      console.error(t("agenda.errors.fetchAdditionalData"), error);
      return reservation;
    }
  };

  useEffect(() => {
    const fetchReservations = async () => {
      setLoading(true);
      const ordersRef = collection(db, `users/${currentUser.uid}/orders`);
      let reservationsQuery;

      if (filterOptions.year) {
        const startOfSelectedYear = startOfYear(new Date(filterOptions.year, 0, 1)).toISOString();
        const endOfSelectedYear = endOfYear(new Date(filterOptions.year, 0, 1)).toISOString();
        reservationsQuery = query(
          ordersRef,
          where("selectedDate", ">=", startOfSelectedYear),
          where("selectedDate", "<", endOfSelectedYear),
          orderBy("selectedDate", "asc")
        );
      } else {
        reservationsQuery = query(ordersRef, orderBy("selectedDate", "asc"));
      }

      try {
        const querySnapshot = await getDocs(reservationsQuery);
        const reservationsData = [];

        for (const doc of querySnapshot.docs) {
          const reservation = { id: doc.id, ...doc.data() };
          const enhancedReservation = await fetchAdditionalData(reservation);
          reservationsData.push(enhancedReservation);
        }

        const filteredData = reservationsData.filter(reservation => {
          const isUpcoming = filterOptions.upcoming ? new Date(reservation.selectedDate) > new Date() : true;
          const isConfirmed = filterOptions.confirmed ? reservation.orderIsConfirmed : true;
          const isPending = filterOptions.pending
            ? !reservation.orderIsConfirmed && !reservation.orderIsCanceled
            : true;
          const isUnpaid = filterOptions.unpaid
            ? reservation.paymentDetails.some(payment => !payment.isPaid && reservation.orderIsConfirmed)
            : true;
          return isUpcoming && isConfirmed && isPending && isUnpaid;
        });

        setReservations(filteredData);
      } catch (error) {
        enqueueSnackbar(t("agenda.notifications.fetchError"), { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    fetchReservations();
  }, [currentUser, filterOptions, t, enqueueSnackbar]);

  const handleDeleteReservation = async (reservationId, reservation) => {
    if (reservation.orderIsConfirmed) {
      enqueueSnackbar(t("agenda.notifications.cannotDeleteValidated"), {
        variant: "error"
      });
      return;
    }

    try {
      const orderRef = doc(db, `users/${currentUser.uid}/orders`, reservationId);
      await deleteDoc(orderRef);
      enqueueSnackbar(t("agenda.notifications.deleteSuccess"), { variant: "success" });
      setReservations(reservations.filter(r => r.id !== reservationId));
    } catch (error) {
      enqueueSnackbar(t("agenda.notifications.deleteError"), { variant: "error" });
    }
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [reservationToDelete, setReservationToDelete] = useState(null);

  const handleDeleteClick = reservation => {
    setReservationToDelete(reservation);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (reservationToDelete) {
      handleDeleteReservation(reservationToDelete.id, reservationToDelete);
      setDeleteDialogOpen(false);
      setReservationToDelete(null);
    }
  };

  const handleOpenCustomerDialog = async customerId => {
    const clientRef = doc(db, `users/${currentUser.uid}/customers`, customerId);
    const clientSnap = await getDoc(clientRef);
    if (clientSnap.exists()) {
      setSelectedCustomer(clientSnap.data());
      setSelectedCustomerId(customerId);
      setDialogOpen(true);
    }
  };

  const handleCloseCustomerDialog = () => {
    setDialogOpen(false);
    setSelectedCustomer(null);
    setSelectedCustomerId(null);
  };

  const toggleMenu = id => {
    setOpenMenuId(openMenuId === id ? null : id);
  };

  return (
    <Box sx={{ maxWidth: "100%", mb: 4 }}>
      <Typography 
        variant="h4" 
        sx={{ 
          mb: 3,
          color: theme => theme.palette.mode === "dark" ? "primary.light" : "primary.main",
          fontWeight: "500"
        }}
      >
        {t("agenda.title")}
      </Typography>

      <FilterMenu setFilterOptions={setFilterOptions} currentYear={filterOptions.year} />

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
          <RotatingLines
            strokeColor={theme.palette.primary.main}
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </Box>
      ) : (
        <TableContainer 
          component={Paper} 
          sx={{ 
            borderRadius: 2,
            boxShadow: theme => theme.palette.mode === "dark" 
              ? "0 4px 6px rgba(0, 0, 0, 0.4)"
              : "0 4px 6px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
            backgroundColor: theme => theme.palette.mode === "dark" 
              ? "background.paper"
              : "#fff"
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "48px", p: 1.5 }} />
                <TableCell sx={{ 
                  fontWeight: 600,
                  color: theme => theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                }}>
                  {t("agenda.serviceType")}
                </TableCell>
                <TableCell sx={{ 
                  fontWeight: 600,
                  color: theme => theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                }}>
                  {t("agenda.date")}
                </TableCell>
                <TableCell sx={{ 
                  fontWeight: 600,
                  color: theme => theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                }}>
                  {t("agenda.clientName")}
                </TableCell>
                <TableCell sx={{ 
                  fontWeight: 600,
                  color: theme => theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                }}>
                  {t("agenda.totalPrice")}
                </TableCell>
                <TableCell sx={{ 
                  fontWeight: 600,
                  color: theme => theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                }}>
                  {t("agenda.mainLocation")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservations.map(reservation => (
                <React.Fragment key={reservation.id}>
                  <TableRow
                    sx={{
                      backgroundColor: reservation.orderIsConfirmed
                        ? theme.palette.mode === "dark"
                          ? "rgba(0, 200, 83, 0.1)"
                          : "rgba(0, 200, 83, 0.05)"
                        : "inherit",
                      transition: "background-color 0.2s",
                      "&:hover": {
                        backgroundColor: theme => theme.palette.mode === "dark"
                          ? "rgba(144, 202, 249, 0.08)"
                          : "rgba(144, 202, 249, 0.04)"
                      }
                    }}
                  >
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => toggleMenu(reservation.id)}
                        sx={{
                          transition: "transform 0.2s",
                          transform: openMenuId === reservation.id ? "rotate(180deg)" : "none"
                        }}
                      >
                        {openMenuId === reservation.id ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 500 }}>{reservation.productType}</TableCell>
                    <TableCell>
                      {new Date(reservation.selectedDate).toLocaleString("fr-FR", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        weekday: "long"
                      })}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        {reservation.clientName}
                        <IconButton
                          onClick={() => handleOpenCustomerDialog(reservation.client_id)}
                          size="small"
                          sx={{ 
                            color: theme => theme.palette.mode === "dark" 
                              ? "primary.light" 
                              : "primary.main"
                          }}
                        >
                          <AccountBoxIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ 
                      fontWeight: 500,
                      color: theme => theme.palette.mode === "dark" 
                        ? "success.light" 
                        : "success.dark"
                    }}>
                      {reservation.totalPrice} €
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        {reservation.locations && reservation.locations.length > 0
                          ? reservation.locations[0].locationWhere.slice(0, 60) ||
                            t("agenda.locationUndefined")
                          : t("agenda.locationUndefined")}
                        {reservation.locations && reservation.locations[0]?.place_id && (
                          <IconButton 
                            component="a"
                            href={`https://www.google.com/maps/place/?q=place_id:${reservation.locations[0].place_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            size="small"
                            sx={{ 
                              color: theme => theme.palette.mode === "dark" 
                                ? "primary.light" 
                                : "primary.main"
                            }}
                          >
                            <MapIcon />
                          </IconButton>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                      <Collapse in={openMenuId === reservation.id} timeout="auto" unmountOnExit>
                        <Box 
                          sx={{ 
                            m: 2,
                            display: "flex",
                            justifyContent: "center",
                            gap: 1,
                            flexWrap: "wrap"
                          }}
                        >
                          <ValidateReservation reservation={reservation} />
                          <CancelReservation reservation={reservation} />
                          <IconButton
                            onClick={() => navigate("/reservation", { state: { reservationData: reservation } })}
                            sx={{ 
                              color: theme => theme.palette.mode === "dark" 
                                ? "primary.light" 
                                : "primary.main"
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <ReservationICS reservation={reservation} />
                          <PaymentManagement reservation={reservation} />
                          <ReservationTodo reservation={reservation} />
                          <ReservationUpload reservation={reservation} />
                          <PDFInvoiceGenerator reservation={reservation} />
                          <Tooltip
                            title={
                              reservation.orderIsConfirmed
                                ? t("agenda.notifications.cannotDeleteValidated")
                                : t("agenda.actions.delete")
                            }
                          >
                            <span>
                              <IconButton
                                onClick={() =>
                                  reservation.orderIsConfirmed
                                    ? enqueueSnackbar(
                                      t("agenda.notifications.cannotDeleteValidated"),
                                      { variant: "error" }
                                    )
                                    : handleDeleteClick(reservation)
                                }
                                sx={{ 
                                  color: reservation.orderIsConfirmed 
                                    ? "action.disabled"
                                    : "error.main"
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <CustomerDetails
        open={dialogOpen}
        handleClose={handleCloseCustomerDialog}
        customer={selectedCustomer}
        customerId={selectedCustomerId}
      />

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>{t("agenda.deleteConfirmation.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("agenda.deleteConfirmation.message")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            {t("common.cancel")}
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            {t("common.delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Agenda;
