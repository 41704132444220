import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import EuroIcon from "@mui/icons-material/Euro";
import PaymentIcon from "@mui/icons-material/Payment";
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";

import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";



const PaymentManagement = ({ reservation }) => {
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const [localPaymentDetails, setLocalPaymentDetails] = useState([...reservation.paymentDetails]);
  const [paymentUpdates, setPaymentUpdates] = useState(
    reservation.paymentDetails.map(() => ({
      paymentMethod: "",
      paymentDate: new Date().toISOString().split("T")[0]
    }))
  );

  const paymentMethods = [
    { id: "CB", name: t("agenda.payments.creditCard") },
    { id: "CHK", name: t("agenda.payments.check") },
    { id: "PP", name: t("agenda.payments.paypal") },
    { id: "SEPA", name: t("agenda.payments.sepa") },
    { id: "ESP", name: t("agenda.payments.cash") }
  ];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const unpaidPaymentsCount = localPaymentDetails.filter(payment => !payment.isPaid).length;

  const handlePaymentUpdate = async index => {
    const updatedPaymentDetails = [...localPaymentDetails];
    const paymentUpdate = paymentUpdates[index];

    updatedPaymentDetails[index] = {
      ...updatedPaymentDetails[index],
      isPaid: true,
      paymentMode: paymentUpdate.paymentMethod,
      paymentDate: paymentUpdate.paymentDate
    };

    const allPaid = updatedPaymentDetails.every(detail => detail.isPaid);
    const fullyPaidDate = allPaid ? new Date().toISOString() : null;

    try {
      await updateDoc(doc(db, `users/${currentUser.uid}/orders/${reservation.id}`), {
        paymentDetails: updatedPaymentDetails,
        fullyPaid: allPaid,
        fullyPaidDate: fullyPaidDate
      });

      setLocalPaymentDetails(updatedPaymentDetails);
      setPaymentUpdates(prev =>
        prev.map((update, i) => (i === index ? { paymentMethod: "", paymentDate: "" } : update))
      );

      enqueueSnackbar(t("agenda.payments.notifications.paymentValidated"), { variant: "success" });

      if (allPaid) {
        enqueueSnackbar(t("agenda.payments.notifications.fullyPaid"), { variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar(t("agenda.payments.notifications.validationError"), { variant: "error" });
    }
  };

  const handleChange = (index, field, value) => {
    const updatedPaymentUpdates = [...paymentUpdates];
    updatedPaymentUpdates[index][field] = value;
    setPaymentUpdates(updatedPaymentUpdates);
  };

  return (
    <>
      <Tooltip
        title={true === reservation.orderIsConfirmed 
          ? t("agenda.payments.manage")
          : t("agenda.payments.confirmFirst")}
      >
        <span>
          <IconButton
            onClick={handleOpen}
            disabled={!reservation.orderIsConfirmed}
          >
            <Badge badgeContent={unpaidPaymentsCount} color="secondary">
              <PaymentIcon color={reservation.orderIsConfirmed ? "primary" : "disabled"} />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{t("agenda.payments.title")}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            {t("agenda.payments.plan")}: {reservation.paymentPlan !== "null" ? reservation.paymentPlan : "1x"}
          </Typography>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("agenda.payments.number")}</TableCell>
                  <TableCell>{t("agenda.payments.amount")}</TableCell>
                  <TableCell>{t("agenda.payments.method")}</TableCell>
                  <TableCell>{t("agenda.payments.date")}</TableCell>
                  <TableCell>{t("common.actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {localPaymentDetails.map((payment, index) => (
                  <TableRow key={index}>
                    <TableCell>#{index + 1}</TableCell>
                    <TableCell>{parseFloat(payment.value).toFixed(2)} €</TableCell>
                    {payment.isPaid ? (
                      <>
                        <TableCell>{payment.paymentMode}</TableCell>
                        <TableCell>{payment.paymentDate}</TableCell>
                        <TableCell>
                          <EuroIcon color="success" />
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>
                          <TextField
                            select
                            fullWidth
                            label={t("agenda.payments.selectMethod")}
                            value={paymentUpdates[index].paymentMethod}
                            onChange={e => handleChange(index, "paymentMethod", e.target.value)}
                          >
                            {paymentMethods.map(method => (
                              <MenuItem key={method.id} value={method.name}>
                                {method.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            type="date"
                            label={t("agenda.payments.selectDate")}
                            value={paymentUpdates[index].paymentDate}
                            onChange={e => handleChange(index, "paymentDate", e.target.value)}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handlePaymentUpdate(index)}
                          >
                            {t("common.validate")}
                          </Button>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("common.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PaymentManagement;
