import React from "react";
import { useTranslation } from "react-i18next";

import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";

const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    handleClose();
  };

  const flags = {
    fr: "🇫🇷",
    en: "🇬🇧"
  };

  return (
    <Box sx={{ mr: 2 }}>
      <Tooltip title={t("settings.language")}>
        <IconButton
          onClick={handleClick}
          sx={{
            fontSize: "1.5rem",
            color: "inherit",
            padding: "8px"
          }}
        >
          {flags[i18n.language] || flags.fr}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            mt: 1,
            minWidth: 120
          }
        }}
      >
        <MenuItem 
          onClick={() => changeLanguage("fr")}
          sx={{ 
            fontSize: "1rem",
            "&:hover": {
              backgroundColor: "action.hover"
            }
          }}
        >
          <Box component="span" sx={{ mr: 2, fontSize: "1.2rem" }}>
            🇫🇷
          </Box>
          {t("settings.languages.fr")}
        </MenuItem>
        <MenuItem 
          onClick={() => changeLanguage("en")}
          sx={{ 
            fontSize: "1rem",
            "&:hover": {
              backgroundColor: "action.hover"
            }
          }}
        >
          <Box component="span" sx={{ mr: 2, fontSize: "1.2rem" }}>
            🇬🇧
          </Box>
          {t("settings.languages.en")}
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default LanguageSwitcher;
