// src/components/Header.js
import React from "react";
import { useNavigate } from "react-router-dom";

import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";

import EZlogoTransparent from "../assets/EZpresta-logo-trans.png";
import { useAuth } from "../contexts/AuthContext";
import LanguageSwitcher from "./LanguageSwitcher";

const Header = ({ handleDrawerToggle }) => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch {
      // Handle error if needed
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        {isMobile && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Box display="flex" alignItems="center" sx={{ flexGrow: 1 }}>
          <img
            src={EZlogoTransparent}
            alt="EzPresta Logo"
            style={{ height: 45, marginRight: 16 }}
          />
        </Box>
        {currentUser && (
          <Box display="flex" alignItems="center">
            <LanguageSwitcher />
            <Typography variant="body1" component="div" sx={{ marginRight: 2 }}>
              {currentUser.name || currentUser.email}
            </Typography>
            <IconButton edge="end" color="inherit">
              <AccountCircle />
            </IconButton>
            <Button color="inherit" onClick={handleLogout}>
              Déconnexion
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
