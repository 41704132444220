import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { CloudUpload as CloudUploadIcon, Delete as DeleteIcon, GetApp as GetAppIcon } from "@mui/icons-material";
import { Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, 
  IconButton, Table, TableBody, TableCell, TableRow, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { deleteObject, getDownloadURL, getStorage, listAll, ref, uploadBytesResumable } from "firebase/storage";

import { useAuth } from "../../contexts/AuthContext";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px"
  }
}));

export default function ReservationUpload({ reservation }) {
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);

  const reservationId = reservation.id;
  const userId = currentUser.uid;
  const storagePath = `${userId}/booking/${reservationId}`;
  const storage = getStorage();

  useEffect(() => {
    fetchFiles();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (progress !== 0) {
      enqueueSnackbar(t("agenda.documents.waitUpload"), { variant: "warning" });
      return;
    }
    setOpen(false);
  };

  const uploadFile = file => {
    if (file.size / 1024 / 1024 > 5) {
      enqueueSnackbar(t("agenda.documents.fileTooLarge"), { variant: "warning" });
      return;
    }

    setLoading(true);
    const fileRef = ref(storage, `${storagePath}/${file.name}`);
    const uploadTask = uploadBytesResumable(fileRef, file);

    uploadTask.on(
      "state_changed",
      snapshot => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(progress);
      },
      error => {
        setLoading(false);
        enqueueSnackbar(t("agenda.documents.notifications.uploadError") + error.message, { variant: "error" });
      },
      () => {
        setLoading(false);
        setProgress(0);
        enqueueSnackbar(t("agenda.documents.notifications.uploadSuccess"), { variant: "success" });
        fetchFiles();
      }
    );
  };

  const fetchFiles = async () => {
    const filesRef = ref(storage, storagePath);
    try {
      const result = await listAll(filesRef);
      setFiles(result.items);
    } catch (error) {
      enqueueSnackbar(t("agenda.documents.notifications.fetchError"), { variant: "error" });
    }
  };

  const downloadFile = async file => {
    const fileRef = ref(storage, `${storagePath}/${file.name}`);
    try {
      const url = await getDownloadURL(fileRef);
      window.open(url);
    } catch (error) {
      enqueueSnackbar(t("agenda.documents.notifications.downloadError"), { variant: "error" });
    }
  };

  const deleteFile = async file => {
    const fileRef = ref(storage, `${storagePath}/${file.name}`);
    try {
      await deleteObject(fileRef);
      enqueueSnackbar(t("agenda.documents.notifications.deleteSuccess"), { variant: "success" });
      fetchFiles();
    } catch (error) {
      enqueueSnackbar(t("agenda.documents.notifications.deleteError"), { variant: "error" });
    }
  };

  return (
    <>
      <Tooltip title={t("agenda.documents.manage")}>
        <IconButton onClick={handleClickOpen}>
          <StyledBadge badgeContent={files.length || 0} color="secondary">
            <CloudUploadIcon color="primary"/>
          </StyledBadge>
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle>{t("agenda.documents.uploadTitle")}</DialogTitle>
        <DialogContent>
          <Table>
            <TableBody>
              {files.map(file => (
                <TableRow key={file.name}>
                  <TableCell>{file.name}</TableCell>
                  <TableCell>
                    <Tooltip title={t("agenda.documents.download")}>
                      <IconButton onClick={() => downloadFile(file)}>
                        <GetAppIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t("agenda.documents.delete")}>
                      <IconButton onClick={() => deleteFile(file)} color="secondary">
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" component="label" disabled={loading}>
            {progress !== 0 
              ? t("agenda.documents.uploadProgress", { progress }) 
              : t("agenda.documents.uploadButton")}
            <input type="file" onChange={e => uploadFile(e.target.files[0])} hidden />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
