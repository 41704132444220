import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, IconButton, Tooltip } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";

const ValidateReservation = ({ reservation }) => {
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const { t } = useTranslation();

  const handleValidate = async () => {
    const orderRef = doc(db, `users/${currentUser.uid}/orders/${reservation.id}`);

    try {
      await updateDoc(orderRef, {
        orderIsConfirmed: true,
        orderIsCanceled: false,
        orderIsConfirmedDate: new Date().toISOString(),
        rejectionDate: null,
        rejectionReason: null
      });

      // Send confirmation email via Firebase Functions
      const functions = getFunctions();
      const sendMessage = httpsCallable(functions, "sendConfirmationOrderV2");

      await sendMessage({
        email: reservation.email,
        username: reservation.clientName,
        catalogType: reservation.productType,
        client: reservation.clientName,
        where: reservation.locations[0]?.locationWhere || t("agenda.locationUndefined"),
        placeId: reservation.locations[0]?.place_id || "N/A",
        priceToPay: reservation.totalPrice,
        date: new Date(reservation.selectedDate).toLocaleString("fr-FR", {
          year: "numeric",
          month: "long",
          day: "numeric",
          weekday: "long"
        })
      });

      enqueueSnackbar(t("agenda.notifications.validateSuccess"), { variant: "success" });
    } catch (error) {
      enqueueSnackbar(t("agenda.notifications.validateError") + error.message, { variant: "error" });
    }
  };

  const handleOpenConfirmation = () => {
    if (reservation.orderIsCanceled) {
      setConfirmDialogOpen(true);
    } else {
      handleValidate();
    }
  };

  const handleCloseConfirmation = () => setConfirmDialogOpen(false);

  const confirmValidation = () => {
    handleValidate();
    handleCloseConfirmation();
  };

  return (
    <>
      <Tooltip title={reservation.orderIsConfirmed 
        ? t("agenda.validation.alreadyValidated") 
        : t("agenda.actions.validate")}>
        <span>
          <IconButton onClick={handleOpenConfirmation} disabled={reservation.orderIsConfirmed}>
            <CheckCircleIcon color={reservation.orderIsConfirmed ? "disabled" : "primary"} />
          </IconButton>
        </span>
      </Tooltip>

      <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmation}>
        <DialogTitle>{t("agenda.validation.confirmTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("agenda.validation.confirmMessage")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation} color="primary">
            {t("common.cancel")}
          </Button>
          <Button onClick={confirmValidation} color="primary">
            {t("common.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ValidateReservation;
