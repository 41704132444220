import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { doc, getDoc, updateDoc } from "firebase/firestore";

import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px"
  }
}));

export default function ReservationTodo({ reservation }) {
  const { currentUser } = useAuth();
  const [tasks, setTasks] = useState(reservation.workflow?.tasks || []); 
  const [newTaskLabel, setNewTaskLabel] = useState("");
  const [openTaskManager, setOpenTaskManager] = useState(false);
  const [openTaskDetails, setOpenTaskDetails] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [comment, setComment] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const userId = currentUser.uid;
  const orderId = reservation.id;

  const refreshTasksFromFirestore = async () => {
    const orderDocRef = doc(db, `users/${userId}/orders/${orderId}`);
    const orderDoc = await getDoc(orderDocRef);

    if (orderDoc.exists()) {
      const workflowData = orderDoc.data().workflow;
      if (workflowData && workflowData.tasks) {
        setTasks(workflowData.tasks);
      } 
    } else {
      enqueueSnackbar(t("agenda.tasks.errors.documentNotFound"), { variant: "error" });
    }
  };

  const handleAddTask = async () => {
    if (!newTaskLabel.trim()) {
      enqueueSnackbar(t("agenda.tasks.errors.emptyTask"), { variant: "warning" });
      return;
    }

    const newTask = {
      label: newTaskLabel,
      done: false,
      dateDone: null,
      comment: ""
    };

    const updatedTasks = [...tasks, newTask];
    setTasks(updatedTasks);
    setNewTaskLabel("");

    const orderDocRef = doc(db, `users/${userId}/orders/${orderId}`);
    await updateDoc(orderDocRef, { "workflow.tasks": updatedTasks });

    enqueueSnackbar(t("agenda.tasks.notifications.addSuccess"), { variant: "success" });
  };

  useEffect(() => {
    refreshTasksFromFirestore();
  }, []);

  const handleOpenTaskManager = () => setOpenTaskManager(true);
  const handleCloseTaskManager = () => setOpenTaskManager(false);

  const handleClickOpenTaskDetails = task => {
    setSelectedTask(task);
    setComment(task.comment || "");
    setOpenTaskDetails(true);
  };

  const handleCloseTaskDetails = () => {
    setOpenTaskDetails(false);
    setSelectedTask(null);
  };

  const handleCheckTask = async taskIndex => {
    const updatedTasks = tasks.map((task, index) =>
      index === taskIndex ? { ...task, done: !task.done, dateDone: !task.done ? new Date().toISOString() : null } : task
    );

    setTasks(updatedTasks);

    const orderDocRef = doc(db, `users/${userId}/orders/${orderId}`);
    await updateDoc(orderDocRef, { "workflow.tasks": updatedTasks });

    enqueueSnackbar(
      tasks[taskIndex].done 
        ? t("agenda.tasks.notifications.taskReactivated")
        : t("agenda.tasks.notifications.taskCompleted"), 
      { variant: "success" }
    );
  };

  const handleSaveComment = async () => {
    const updatedTasks = tasks.map(task =>
      task === selectedTask ? { ...task, comment } : task
    );

    setTasks(updatedTasks);

    const orderDocRef = doc(db, `users/${userId}/orders/${orderId}`);
    await updateDoc(orderDocRef, { "workflow.tasks": updatedTasks });

    enqueueSnackbar(t("agenda.tasks.notifications.commentAdded"), { variant: "success" });
    handleCloseTaskDetails();
  };

  const handleDeleteTask = async taskIndex => {
    const updatedTasks = tasks.filter((_, index) => index !== taskIndex);
    
    setTasks(updatedTasks);

    const orderDocRef = doc(db, `users/${userId}/orders/${orderId}`);
    await updateDoc(orderDocRef, { "workflow.tasks": updatedTasks });

    enqueueSnackbar(t("agenda.tasks.notifications.deleteSuccess"), { variant: "success" });
  };

  const countTask = () => {
    return tasks.filter(task => !task.done).length;
  };

  return (
    <>
      <Tooltip title={t("agenda.tasks.manage")}>
        <IconButton onClick={handleOpenTaskManager}>
          <StyledBadge badgeContent={countTask()} color="secondary">
            <AssignmentIcon color="primary" />
          </StyledBadge>
        </IconButton>
      </Tooltip>

      <Dialog open={openTaskManager} onClose={handleCloseTaskManager} fullWidth maxWidth="sm">
        <DialogTitle>{t("agenda.tasks.listTitle")}</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>{t("agenda.tasks.task")}</TableCell>
                  <TableCell>{t("common.actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks.map((task, index) => (
                  <TableRow key={index} style={task.done ? { textDecorationLine: "line-through" } : {}}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{task.label}</TableCell>
                    <TableCell>
                      <Tooltip title={task.done 
                        ? t("agenda.tasks.reactivateTask") 
                        : t("agenda.tasks.markAsComplete")}>
                        <Checkbox
                          checked={task.done}
                          onChange={() => handleCheckTask(index)}
                          icon={<CheckCircleIcon />}
                          checkedIcon={<CheckCircleIcon color="success" />}
                        />
                      </Tooltip>
                      <Tooltip title={t("agenda.tasks.addComment")}>
                        <IconButton onClick={() => handleClickOpenTaskDetails(task)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("agenda.tasks.deleteTask")}>
                        <IconButton onClick={() => handleDeleteTask(index)} color="secondary">
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TextField
            fullWidth
            variant="outlined"
            label={t("agenda.tasks.newTask")}
            value={newTaskLabel}
            onChange={e => setNewTaskLabel(e.target.value)}
            margin="dense"
          />
          <Button onClick={handleAddTask} color="primary" variant="contained" style={{ marginTop: 8 }}>
            {t("agenda.tasks.addTask")}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTaskManager} color="primary">
            {t("common.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openTaskDetails} onClose={handleCloseTaskDetails}>
        <DialogTitle>{t("agenda.tasks.details")}</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            <strong>{t("agenda.tasks.name")}:</strong> {selectedTask?.label}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            <strong>{t("agenda.tasks.completionDate")}:</strong> {
              selectedTask?.dateDone
                ? new Date(selectedTask.dateDone).toLocaleString("fr-FR", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric"
                })
                : t("agenda.tasks.notCompleted")
            }
          </Typography>
          <TextField
            fullWidth
            margin="dense"
            label={t("agenda.tasks.comment")}
            multiline
            rows={3}
            value={comment}
            onChange={e => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTaskDetails} color="primary">
            {t("common.cancel")}
          </Button>
          <Button onClick={handleSaveComment} color="secondary">
            {t("common.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
