import { useState } from "react";
import { useTranslation } from "react-i18next";
import ICalendarLink from "react-icalendar-link";

import EventIcon from "@mui/icons-material/Event";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";

export default function ReservationICS({ reservation }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const calcEndTime = (start, duration) => {
    let endTime = new Date(start);
    endTime.setHours(endTime.getHours() + duration);
    return endTime;
  };

  const filename = `EZPresta-${reservation.productType}-${reservation.clientName}.ics`;
  const removeHour = value => value.split("T")[0];

  const event = {
    title: t("agenda.calendar.eventTitle", {
      type: reservation.productType,
      client: reservation.clientName
    }),
    description: t("agenda.calendar.eventDescription", {
      client: reservation.clientName
    }),
    startTime: reservation.selectedDate,
    endTime: calcEndTime(reservation.selectedDate, 8),
    location: reservation.locations[0]?.locationWhere || t("agenda.locationUndefined")
  };

  const eventAllDay = {
    title: t("agenda.calendar.eventTitle", {
      type: reservation.productType,
      client: reservation.clientName
    }),
    description: t("agenda.calendar.eventDescription", {
      client: reservation.clientName
    }),
    startTime: removeHour(reservation.selectedDate),
    location: reservation.locations[0]?.locationWhere || t("agenda.locationUndefined")
  };

  const getEvent = () => (reservation.catalogDuration === 24 
    ? eventAllDay 
    : event);

  const addTimeToDate = (date, hours, minutes) => {
    date.setHours(date.getHours() + hours);
    date.setMinutes(date.getMinutes() + minutes);
    return date;
  };

  const rawContent = t("agenda.calendar.reminder");

  const makeGoogleLink = () => {
    let start = new Date(reservation.selectedDate).toISOString().replace(/\.|:|-/g, "");
    let duration = 8;
    let end = addTimeToDate(new Date(reservation.selectedDate), duration, 0)
      .toISOString()
      .replace(/\.|:|-/g, "");
    let location = reservation.locations[0]?.locationWhere || t("agenda.locationUndefined");
    let title = t("agenda.calendar.eventTitle", {
      type: reservation.productType,
      client: reservation.clientName
    });

    let link = `https://calendar.google.com/calendar/u/0/r/eventedit?dates=${start}/${end}&location=${location}&text=${title}`;
    window.open(link, "_blank", "noopener,noreferrer");
    handleClose();
  };

  return (
    <>
      <Tooltip title={t("agenda.calendar.exportTooltip")}>
        <IconButton onClick={handleClick} color="primary">
          <EventIcon />
        </IconButton>
      </Tooltip>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={makeGoogleLink}>{t("agenda.calendar.googleCalendar")}</MenuItem>
        <ICalendarLink filename={filename} event={event} rawContent={rawContent} 
          style={{ textDecoration: "none", color: "inherit" }}>
          <MenuItem onClick={handleClose}>{t("agenda.calendar.ics")}</MenuItem>
        </ICalendarLink>
      </Menu>
    </>
  );
}
