// src/App.js
import { useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from "react-router-dom";
import { SnackbarProvider } from "notistack";

import Box from "@mui/material/Box";
import { blue, grey } from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme,ThemeProvider } from "@mui/material/styles";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import WorkflowForm from "./components/WorkflowForm";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import Agenda from "./pages/Agenda";
import Catalogue from "./pages/Catalogue";
import Customers from "./pages/Customers";
import CustomerSource from "./pages/CustomerSource";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RejectionReasons from "./pages/RejectionReasons";
import Reservation from "./pages/Reservation";
import Settings from "./pages/Settings";
import Workflow from "./pages/Workflow";

const App = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: {
        light: blue[300],
        main: "#096dd9",//blue[500],
        dark: blue[700],
        darker: blue[900]
      },
      action: {
        hover: darkMode ? blue[800] : grey[50]
      }
    },
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: ({ theme }) => ({
            backgroundColor: theme.palette.mode === "dark" ? grey[900] : "#fff",
            color: theme.palette.mode === "dark" ? "#fff" : grey[900]
          })
        }
      },
      MuiListItem: {
        styleOverrides: {
          root: ({ theme }) => ({
            "&:hover": {
              backgroundColor: theme.palette.mode === "dark" ? blue[900] : blue[50],
              "& .MuiListItemIcon-root": {
                color: theme.palette.mode === "dark" ? blue[300] : blue[700]
              }
            },
            "& .MuiListItemIcon-root": {
              color: theme.palette.mode === "dark" ? grey[400] : grey[700]
            }
          })
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: ({ theme }) => ({
            ["tbody &"]: {
              "&:nth-of-type(odd)": {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? theme.palette.grey[800]
                    : theme.palette.action.hover
              },
              "&:hover": {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? theme.palette.grey[700]
                    : blue[50]
              }
            }
          }),
          head: ({ theme }) => ({
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.grey[900]
                : blue[200]
          })
        }
      }
    }
  });

  const toggleTheme = () => {
    setDarkMode(prevMode => !prevMode);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3}>
        <AuthProvider>
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route
                path="*"
                element={
                  <PrivateLayout 
                    toggleTheme={toggleTheme} 
                    darkMode={darkMode}
                    mobileOpen={mobileOpen}
                    handleDrawerToggle={handleDrawerToggle}
                  >
                    <Routes>
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/customers" element={<Customers />} />
                      <Route path="/catalogue" element={<Catalogue />} />
                      <Route path="/reservation" element={<Reservation />} />
                      <Route path="/settings" element={<Settings />} />
                      <Route path="/workflow" element={<Workflow />} />
                      <Route
                        path="/edit-workflow/:workflowId"
                        element={<WorkflowForm />}
                      />
                      <Route
                        path="/customer-source"
                        element={<CustomerSource />}
                      />
                      <Route path="/rejection-reasons" element={<RejectionReasons />} />
                      <Route
                        path="*"
                        element={<Navigate to="/dashboard" replace />}
                      />
                      <Route path="/agenda" element={<Agenda />} />
                    </Routes>
                  </PrivateLayout>
                }
              />
            </Routes>
          </Router>
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

function PrivateLayout({ children, toggleTheme, darkMode, mobileOpen, handleDrawerToggle }) {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Header handleDrawerToggle={handleDrawerToggle} />
      <Sidebar 
        mobileOpen={mobileOpen} 
        handleDrawerToggle={handleDrawerToggle} 
        darkMode={darkMode}
        toggleTheme={toggleTheme}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginTop: "64px",
          zIndex: 0
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default App;
