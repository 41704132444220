import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import validator from "validator";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";

import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase";

const CustomerForm = ({ open, handleClose, customerId, userId, onSave }) => {
  const { currentUser } = useAuth();
  const { t } = useTranslation();

  if (!userId) {
    userId = currentUser.uid;
  }

  const [formData, setFormData] = useState({
    firstname: "",
    email: "",
    phone: "",
    userAddress: "",
    userNote: "",
    company: "",
    source: "",
    sourceOther: ""
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [sources, setSources] = useState([]);

  useEffect(() => {
    if (!customerId) {
      setFormData({
        firstname: "",
        email: "",
        phone: "",
        userAddress: "",
        userNote: "",
        company: "",
        source: "",
        sourceOther: ""
      });
    } else {
      const fetchCustomer = async () => {
        setLoading(true);
        const customerDoc = doc(db, `users/${userId}/customers`, customerId);
        const docSnap = await getDoc(customerDoc);
        if (docSnap.exists()) {
          setFormData(docSnap.data());
        }
        setLoading(false);
      };

      fetchCustomer();
    }
  }, [customerId, userId]);

  useEffect(() => {
    const fetchSources = async () => {
      if (userId) {
        const sourcesCollection = collection(
          db,
          `users/${userId}/customerSource`
        );
        const sourcesSnapshot = await getDocs(sourcesCollection);
        const sourcesList = sourcesSnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name
        }));
        setSources(sourcesList);
      }
    };

    fetchSources();
  }, [userId]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ""
    }));
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
      sourceOther: value === "other" ? prevData.sourceOther : ""
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstname.trim())
      newErrors.firstname = t("clients.validation.nameRequired");
    if (!formData.email.trim()) {
      newErrors.email = t("clients.validation.emailRequired");
    } else {
      if (!validator.isEmail(formData.email))
        newErrors.email = t("clients.validation.emailInvalid");
    }
    if (!formData.phone.trim()) {
      newErrors.phone = t("clients.validation.phoneRequired");
    } else {
      if (!validator.isMobilePhone(formData.phone, "any", { strictMode: true }))
        newErrors.phone = t("clients.validation.phoneInvalid");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    if (userId) {
      setLoading(true);
      const newCustomerId = customerId || new Date().getTime().toString();
      const customerRef = doc(db, `users/${userId}/customers`, newCustomerId);

      try {
        await setDoc(customerRef, formData, { merge: true });
        enqueueSnackbar(t("clients.notifications.saveSuccess"), {
          variant: "success"
        });

        const newCustomer = { id: newCustomerId, ...formData };
        onSave(newCustomer);
        handleClose();
      } catch (error) {
        enqueueSnackbar(t("clients.notifications.saveError") + error.message, {
          variant: "error"
        });
      } finally {
        setLoading(false);
      }
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {customerId ? t("clients.form.editTitle") : t("clients.form.createTitle")}
      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                label={t("clients.name")}
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
                sx={{ "& .MuiInputBase-input": { fontSize: "16px" } }}
                error={!!errors.firstname}
                helperText={errors.firstname}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                label={t("clients.email")}
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                sx={{ "& .MuiInputBase-input": { fontSize: "16px" } }}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                label={t("clients.phone")}
                name="phone"
                type="tel"
                value={formData.phone}
                onChange={handleChange}
                sx={{ "& .MuiInputBase-input": { fontSize: "16px" } }}
                error={!!errors.phone}
                helperText={errors.phone}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                fullWidth
                label={t("clients.company")}
                name="company"
                value={formData.company}
                onChange={handleChange}
                sx={{ "& .MuiInputBase-input": { fontSize: "16px" } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                label={t("clients.address")}
                name="userAddress"
                value={formData.userAddress}
                onChange={handleChange}
                sx={{ "& .MuiInputBase-input": { fontSize: "16px" } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                label={t("clients.note")}
                name="userNote"
                value={formData.userNote}
                onChange={handleChange}
                sx={{ "& .MuiInputBase-input": { fontSize: "16px" } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {t("clients.form.sourceQuestion")}
              <Select
                margin="normal"
                fullWidth
                label={t("clients.source")}
                name="source"
                value={formData.source}
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  {t("clients.form.selectSource")}
                </MenuItem>
                {sources.map(source => (
                  <MenuItem key={source.id} value={source.name}>
                    {source.name}
                  </MenuItem>
                ))}
                <MenuItem value="other">{t("clients.form.otherSource")}</MenuItem>
              </Select>

              {formData.source === "other" && (
                <TextField
                  margin="normal"
                  fullWidth
                  label={t("clients.sourceOther")}
                  name="sourceOther"
                  value={formData.sourceOther}
                  onChange={handleChange}
                  sx={{ "& .MuiInputBase-input": { fontSize: "16px" } }}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t("common.cancel")}
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={loading}>
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerForm;
