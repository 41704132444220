import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EventIcon from "@mui/icons-material/Event";
import LightModeIcon from "@mui/icons-material/LightMode";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import StoreIcon from "@mui/icons-material/Store";
import WorkIcon from "@mui/icons-material/Work";
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Avatar from "@mui/material/Avatar";

import { useAuth } from "../contexts/AuthContext";

const Sidebar = ({ mobileOpen, handleDrawerToggle, darkMode, toggleTheme }) => {
  const { currentUser } = useAuth();
  const [openSettings, setOpenSettings] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const handleSettingsClick = () => {
    setOpenSettings(!openSettings);
  };

  const menuItems = [
    { text: t("navigation.dashboard"), icon: <DashboardIcon />, path: "/dashboard" },
    { text: t("navigation.catalog"), icon: <StoreIcon />, path: "/Catalogue" },
    { text: t("navigation.clients"), icon: <PeopleIcon />, path: "/Customers" },
    { text: t("navigation.agenda"), icon: <EventIcon />, path: "/Agenda" },
    { text: t("navigation.newAppointment"), icon: <AddCircleIcon />, path: "/new-appointment" },
    {
      text: t("navigation.newReservation"),
      icon: <AddShoppingCartIcon />,
      path: "/Reservation"
    }
  ];

  const drawer = (
    <>
      <Toolbar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "16px"
        }}
      >
        {currentUser && currentUser.logo ? (
          <Avatar
            src={currentUser.logo}
            alt={currentUser.username || currentUser.email}
            sx={{ width: 100, height: 100 }}
          />
        ) : (
          <Avatar sx={{ width: 100, height: 100 }} />
        )}
      </Box>
      <Divider />
      <List>
        {menuItems.map(item => (
          <ListItem 
            key={item.text}
            disablePadding
            sx={{
              color: theme => theme.palette.mode === "dark" ? "white" : "inherit",
              "& .MuiListItemIcon-root": {
                color: theme => theme.palette.mode === "dark" ? "white" : "inherit"
              },
              "&:hover": {
                backgroundColor: theme => 
                  theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.08)" : "rgba(0, 0, 0, 0.04)",
                "& .MuiListItemIcon-root": {
                  color: theme => theme.palette.primary.main
                }
              }
            }}
          >
            <ListItemButton 
              component={Link} 
              to={item.path}
              onClick={() => isMobile && handleDrawerToggle()}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}

        <ListItem disablePadding>
          <ListItemButton onClick={handleSettingsClick}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={t("navigation.settings")} />
            {openSettings ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>

        <Collapse in={openSettings} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem disablePadding>
              <ListItemButton 
                component={Link} 
                to="/settings" 
                sx={{ pl: 4 }}
                onClick={() => isMobile && handleDrawerToggle()}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={t("navigation.settings")} />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/customer-source"
                sx={{ pl: 4 }}
                onClick={() => isMobile && handleDrawerToggle()}
              >
                <ListItemIcon>
                  <ContactPhoneIcon />
                </ListItemIcon>
                <ListItemText primary={t("navigation.customerSource")} />
              </ListItemButton>
            </ListItem>
            
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/rejection-reasons"
                sx={{ pl: 4 }}
                onClick={() => isMobile && handleDrawerToggle()}
              >
                <ListItemIcon>
                  <CancelPresentationIcon />
                </ListItemIcon>
                <ListItemText primary={t("navigation.rejectionReasons")} />
              </ListItemButton>
            </ListItem>
            
            <ListItem disablePadding>
              <ListItemButton 
                component={Link} 
                to="/workflow" 
                sx={{ pl: 4 }}
                onClick={() => isMobile && handleDrawerToggle()}
              >
                <ListItemIcon>
                  <WorkIcon />
                </ListItemIcon>
                <ListItemText primary={t("navigation.workflow")} />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton 
                onClick={toggleTheme}
                sx={{ pl: 4 }}
              >
                <ListItemIcon>
                  {darkMode ? <LightModeIcon /> : <DarkModeIcon />}
                </ListItemIcon>
                <ListItemText primary={darkMode ? t("navigation.lightMode") : t("navigation.darkMode")} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
      </List>
    </>
  );

  return (
    <Drawer
      variant={isMobile ? "temporary" : "permanent"}
      open={isMobile ? mobileOpen : true}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true // Better open performance on mobile
      }}
      sx={{
        width: 240,
        flexShrink: 0,
        ["& .MuiDrawer-paper"]: {
          width: 240,
          boxSizing: "border-box",
          top: "64px",
          height: "calc(100% - 64px)",
          backgroundColor: theme => theme.palette.mode === "dark" ? "rgb(18, 18, 18)" : "#fff",
          color: theme => theme.palette.mode === "dark" ? "#fff" : "inherit"
        }
      }}
    >
      {drawer}
    </Drawer>
  );
};

export default Sidebar;
