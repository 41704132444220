import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc
} from "firebase/firestore";

import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase";

const CategoryManager = ({ open, handleClose }) => {
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [editingCategory, setEditingCategory] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      if (currentUser) {
        try {
          const categoriesRef = collection(
            db,
            `users/${currentUser.uid}/categories`
          );
          const snapshot = await getDocs(categoriesRef);
          const categoriesList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setCategories(categoriesList);
        } catch (error) {
          enqueueSnackbar(t("catalog.categories.notifications.fetchError") + error.message, {
            variant: "error"
          });
        }
      }
    };

    if (open) {
      fetchCategories();
    }
  }, [currentUser, open, enqueueSnackbar, t]);

  const handleAddCategory = async () => {
    if (!newCategory) return;
    try {
      const categoriesRef = collection(
        db,
        `users/${currentUser.uid}/categories`
      );
      const docRef = await addDoc(categoriesRef, { name: newCategory });
      setCategories([...categories, { id: docRef.id, name: newCategory }]);
      setNewCategory("");
      enqueueSnackbar(t("catalog.categories.notifications.addSuccess"), { variant: "success" });
    } catch (error) {
      enqueueSnackbar(t("catalog.categories.notifications.addError") + error.message, {
        variant: "error"
      });
    }
  };

  const handleEditCategory = async (id, name) => {
    try {
      const categoryDoc = doc(db, `users/${currentUser.uid}/categories`, id);
      await updateDoc(categoryDoc, { name });
      setCategories(
        categories.map(category =>
          category.id === id ? { id, name } : category
        )
      );
      setEditingCategory(null);
      enqueueSnackbar(t("catalog.categories.notifications.updateSuccess"), { variant: "success" });
    } catch (error) {
      enqueueSnackbar(t("catalog.categories.notifications.updateError") + error.message, {
        variant: "error"
      });
    }
  };

  const handleDeleteClick = id => {
    setCategoryToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteCategory = async () => {
    try {
      const categoryDoc = doc(db, `users/${currentUser.uid}/categories`, categoryToDelete);
      await deleteDoc(categoryDoc);
      setCategories(categories.filter(category => category.id !== categoryToDelete));
      enqueueSnackbar(t("catalog.categories.notifications.deleteSuccess"), { variant: "success" });
    } catch (error) {
      enqueueSnackbar(t("catalog.categories.notifications.deleteError") + error.message, {
        variant: "error"
      });
    } finally {
      setDeleteDialogOpen(false);
      setCategoryToDelete(null);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t("catalog.categories.title")}</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            {t("catalog.categories.addNew")}
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              label={t("catalog.categories.name")}
              value={newCategory}
              onChange={e => setNewCategory(e.target.value)}
              fullWidth
            />
            <IconButton color="primary" onClick={handleAddCategory}>
              <SaveIcon />
            </IconButton>
          </Box>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>{t("catalog.categories.type")}</TableCell>
                <TableCell>{t("common.actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories.map((category, index) => (
                <TableRow key={category.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {editingCategory === category.id ? (
                      <TextField
                        value={category.name}
                        onChange={e =>
                          setCategories(
                            categories.map(cat =>
                              cat.id === category.id
                                ? { ...cat, name: e.target.value }
                                : cat
                            ))
                        }
                        fullWidth
                      />
                    ) : (
                      category.name
                    )}
                  </TableCell>
                  <TableCell>
                    {editingCategory === category.id ? (
                      <>
                        <IconButton
                          onClick={() =>
                            handleEditCategory(category.id, category.name)
                          }
                        >
                          <SaveIcon />
                        </IconButton>
                        <Button onClick={() => setEditingCategory(null)} color="secondary">
                          {t("common.cancel")}
                        </Button>
                      </>
                    ) : (
                      <>
                        <IconButton
                          onClick={() => setEditingCategory(category.id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDeleteClick(category.id)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("common.close")}
        </Button>
      </DialogActions>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>{t("catalog.categories.deleteConfirmation.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("catalog.categories.deleteConfirmation.message")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            {t("common.cancel")}
          </Button>
          <Button onClick={handleDeleteCategory} color="error">
            {t("common.delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default CategoryManager;
