import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RotatingLines } from "react-loader-spinner";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useSnackbar } from "notistack";

import { Add as AddIcon, Delete, Edit } from "@mui/icons-material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from "@mui/material";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";

import CustomerDetails from "../components/CustomerDetails";
import CustomerForm from "../components/CustomerForm";
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase";

const formatPhoneNumber = phoneNumber => {
  if (!phoneNumber) return "";
  const phoneNumberParsed = parsePhoneNumberFromString(phoneNumber, "FR");
  if (phoneNumberParsed) {
    return phoneNumberParsed.formatInternational();
  }
  return phoneNumber;
};

const phoneGetURI = phoneNumber => {
  if (!phoneNumber) return "";
  const phoneNumberParsed = parsePhoneNumberFromString(phoneNumber, "FR");
  if (phoneNumberParsed) {
    return phoneNumberParsed.getURI();
  }
  return phoneNumber;
};

const Customers = () => {
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();

  // New state for delete confirmation dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(null);

  useEffect(() => {
    const fetchCustomers = async () => {
      if (currentUser) {
        try {
          const customersRef = collection(db, `users/${currentUser.uid}/customers`);
          const snapshot = await getDocs(customersRef);
          const customersList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          setCustomers(customersList);
          setLoading(false);
        } catch (error) {
          enqueueSnackbar(t("clients.notifications.fetchError") + error.message, {
            variant: "error"
          });
          setLoading(false);
        }
      }
    };

    fetchCustomers();
  }, [currentUser, enqueueSnackbar, t]);

  const handleOpenDialog = (customer, customerId = null) => {
    setSelectedCustomer(customer);
    setSelectedCustomerId(customerId);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedCustomer(null);
    setSelectedCustomerId(null);
  };

  const handleOpenForm = (customer = null, customerId = null) => {
    setSelectedCustomer(customer);
    setSelectedCustomerId(customerId);
    setFormOpen(true);
  };

  const handleCloseForm = () => {
    setFormOpen(false);
    setSelectedCustomer(null);
    setSelectedCustomerId(null);
  };

  const handleSaveCustomer = newCustomer => {
    setCustomers(prevCustomers =>
      prevCustomers.some(customer => customer.id === newCustomer.id)
        ? prevCustomers.map(customer =>
          customer.id === newCustomer.id ? newCustomer : customer
        )
        : [...prevCustomers, newCustomer]
    );
    handleCloseForm();
  };

  // Modified to open confirmation dialog instead of deleting immediately
  const handleDeleteClick = customerId => {
    setCustomerToDelete(customerId);
    setDeleteDialogOpen(true);
  };

  // Actual delete function
  const handleDeleteCustomer = async () => {
    try {
      await deleteDoc(doc(db, `users/${currentUser.uid}/customers`, customerToDelete));
      setCustomers(customers.filter(customer => customer.id !== customerToDelete));
      enqueueSnackbar(t("clients.notifications.deleteSuccess"), {
        variant: "success"
      });
    } catch (error) {
      enqueueSnackbar(t("clients.notifications.deleteError") + error.message, {
        variant: "error"
      });
    } finally {
      setDeleteDialogOpen(false);
      setCustomerToDelete(null);
    }
  };

  const fullFirstName = customer => {
    if (customer.company) {
      return `${customer.firstname} (${customer.company})`;
    }
    return customer.firstname;
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
        <RotatingLines
          strokeColor={theme.palette.primary.main}
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: "100%", mb: 4 }}>
      <Typography 
        variant="h4" 
        sx={{ 
          mb: 3,
          color: theme => theme.palette.mode === "dark" ? "primary.light" : "primary.main",
          fontWeight: "500"
        }}
      >
        {t("clients.title")}
      </Typography>

      <TableContainer 
        component={Paper} 
        sx={{ 
          borderRadius: 2,
          boxShadow: theme => theme.palette.mode === "dark" 
            ? "0 4px 6px rgba(0, 0, 0, 0.4)"
            : "0 4px 6px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
          backgroundColor: theme => theme.palette.mode === "dark" 
            ? "background.paper"
            : "#fff"
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ 
                fontWeight: 600,
                color: theme => theme.palette.mode === "dark" ? "primary.light" : "primary.main"
              }}>
                {t("clients.name")}
              </TableCell>
              <TableCell sx={{ 
                fontWeight: 600,
                color: theme => theme.palette.mode === "dark" ? "primary.light" : "primary.main"
              }}>
                {t("clients.email")}
              </TableCell>
              <TableCell sx={{ 
                fontWeight: 600,
                color: theme => theme.palette.mode === "dark" ? "primary.light" : "primary.main"
              }}>
                {t("clients.phone")}
              </TableCell>
              <TableCell sx={{ 
                fontWeight: 600,
                color: theme => theme.palette.mode === "dark" ? "primary.light" : "primary.main"
              }}>
                {t("common.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map(customer => (
              <TableRow 
                key={customer.id}
                sx={{
                  transition: "background-color 0.2s",
                  "&:hover": {
                    backgroundColor: theme => theme.palette.mode === "dark"
                      ? "rgba(144, 202, 249, 0.08)"
                      : "rgba(144, 202, 249, 0.04)"
                  }
                }}
              >
                <TableCell sx={{ fontWeight: 500 }}>
                  {fullFirstName(customer)}
                </TableCell>
                <TableCell>
                  <a
                    href={`mailto:${customer.email}`}
                    style={{
                      color: theme.palette.mode === "dark" 
                        ? theme.palette.primary.light
                        : theme.palette.primary.main,
                      textDecoration: "none"
                    }}
                  >
                    {customer.email}
                  </a>
                </TableCell>
                <TableCell>
                  <a
                    href={phoneGetURI(customer?.phone)}
                    style={{
                      color: theme.palette.mode === "dark" 
                        ? theme.palette.primary.light
                        : theme.palette.primary.main,
                      textDecoration: "none"
                    }}
                  >
                    {formatPhoneNumber(customer?.phone)}
                  </a>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <IconButton
                      onClick={() => handleOpenDialog(customer, customer.id)}
                      size="small"
                      sx={{ 
                        color: theme => theme.palette.mode === "dark" 
                          ? "primary.light" 
                          : "primary.main"
                      }}
                    >
                      <AccountBoxIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleOpenForm(customer, customer.id)}
                      size="small"
                      sx={{ 
                        color: theme => theme.palette.mode === "dark" 
                          ? "primary.light" 
                          : "primary.main"
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton 
                      onClick={() => handleDeleteClick(customer.id)}
                      size="small"
                      sx={{ color: "error.main" }}
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomerDetails
        open={dialogOpen}
        handleClose={handleCloseDialog}
        customer={selectedCustomer}
        customerId={selectedCustomerId}
      />

      <CustomerForm
        open={formOpen}
        handleClose={handleCloseForm}
        customer={selectedCustomer}
        customerId={selectedCustomerId}
        userId={currentUser.uid}
        onSave={handleSaveCustomer}
      />

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>{t("clients.deleteConfirmation.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("clients.deleteConfirmation.message")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            {t("common.cancel")}
          </Button>
          <Button onClick={handleDeleteCustomer} color="error">
            {t("common.delete")}
          </Button>
        </DialogActions>
      </Dialog>

      <Fab
        variant="extended"
        color="primary"
        aria-label="add"
        sx={{
          position: "fixed",
          bottom: theme => theme.spacing(2),
          right: theme => theme.spacing(2),
          width: 58,
          overflow: "hidden",
          whiteSpace: "nowrap",
          fontSize: 0,
          transition: "width 0.5s, border-radius 0.5s, background-color 0.5s",
          "&:hover": {
            fontSize: 15,
            width: 300,
            borderRadius: 15
          }
        }}
        onClick={() => handleOpenForm(null, null)}
      >
        <AddIcon
          sx={{ marginRight: theme => theme.spacing(1), fontSize: 32 }}
        />
        {t("clients.addClient")}
      </Fab>
    </Box>
  );
};

export default Customers;
