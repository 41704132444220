import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RotatingLines } from "react-loader-spinner";
import { useSnackbar } from "notistack";

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Search as SearchIcon
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";

import CategoryManager from "../components/CategoryManager";
import ProductForm from "../components/ProductForm";
import { getFormattedDuration, price } from "../components/utils";
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase";

const Catalogue = () => {
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [catalog, setCatalog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formOpen, setFormOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [search, setSearch] = useState("");
  const [categoryManagerOpen, setCategoryManagerOpen] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();

  // New state for delete confirmation dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);

  useEffect(() => {
    const fetchCatalog = async () => {
      if (currentUser) {
        try {
          const catalogRef = collection(db, `users/${currentUser.uid}/catalog`);
          const snapshot = await getDocs(catalogRef);
          const catalogList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setCatalog(catalogList);
          setLoading(false);
        } catch (error) {
          enqueueSnackbar(t("catalog.notifications.fetchError") + error.message, {
            variant: "error"
          });
          setLoading(false);
        }
      }
    };

    fetchCatalog();
  }, [currentUser, enqueueSnackbar, t]);

  const handleSearchChange = event => {
    setSearch(event.target.value);
  };

  const handleOpenForm = (product, productId = null) => {
    setSelectedProduct(product);
    setSelectedProductId(productId);
    setFormOpen(true);
  };

  const handleCloseForm = () => {
    setFormOpen(false);
    setSelectedProduct(null);
    setSelectedProductId(null);
  };

  const handleSaveProduct = savedProduct => {
    const updatedCatalog = [...catalog];
    const productIndex = updatedCatalog.findIndex(
      product => product.id === savedProduct.id
    );

    if (productIndex >= 0) {
      updatedCatalog[productIndex] = savedProduct;
    } else {
      updatedCatalog.push(savedProduct);
    }

    setCatalog(updatedCatalog);
    handleCloseForm();
  };

  // Modified to open confirmation dialog instead of deleting immediately
  const handleDeleteClick = productId => {
    setProductToDelete(productId);
    setDeleteDialogOpen(true);
  };

  // Actual delete function
  const handleDeleteProduct = async () => {
    try {
      await deleteDoc(doc(db, `users/${currentUser.uid}/catalog`, productToDelete));
      setCatalog(catalog.filter(product => product.id !== productToDelete));
      enqueueSnackbar(t("catalog.notifications.deleteSuccess"), { variant: "success" });
    } catch (error) {
      enqueueSnackbar(t("catalog.notifications.deleteError") + error.message, {
        variant: "error"
      });
    } finally {
      setDeleteDialogOpen(false);
      setProductToDelete(null);
    }
  };

  const filteredCatalog = catalog.filter(
    item =>
      (item.type && item.type.toLowerCase().includes(search.toLowerCase())) ||
      (item.name && item.name.toLowerCase().includes(search.toLowerCase())) ||
      (item.description &&
        item.description.toLowerCase().includes(search.toLowerCase()))
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
        <RotatingLines
          strokeColor={theme.palette.primary.main}
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: "100%", mb: 4 }}>
      <Typography 
        variant="h4" 
        sx={{ 
          mb: 3,
          color: theme => theme.palette.mode === "dark" ? "primary.light" : "primary.main",
          fontWeight: "500"
        }}
      >
        {t("catalog.title")}
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3, gap: 2 }}>
        <TextField
          variant="outlined"
          placeholder={t("common.search")}
          value={search}
          onChange={handleSearchChange}
          sx={{ 
            minWidth: 300,
            "& .MuiOutlinedInput-root": {
              backgroundColor: theme => theme.palette.mode === "dark" 
                ? "rgba(255, 255, 255, 0.05)" 
                : "rgba(0, 0, 0, 0.02)"
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            )
          }}
        />
        <Box sx={{ display: "flex", gap: 2 }}>
          <Fab
            variant="extended"
            color="primary"
            onClick={() => handleOpenForm(null, null)}
            size="medium"
          >
            <AddIcon sx={{ mr: 1 }} />
            {t("catalog.addProduct")}
          </Fab>
          <Fab
            variant="extended"
            color="primary"
            onClick={() => setCategoryManagerOpen(true)}
            size="medium"
          >
            <AddIcon sx={{ mr: 1 }} />
            {t("catalog.manageCategories")}
          </Fab>
        </Box>
      </Box>

      <TableContainer 
        component={Paper} 
        sx={{ 
          borderRadius: 2,
          boxShadow: theme => theme.palette.mode === "dark" 
            ? "0 4px 6px rgba(0, 0, 0, 0.4)"
            : "0 4px 6px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
          backgroundColor: theme => theme.palette.mode === "dark" 
            ? "background.paper"
            : "#fff"
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ 
                fontWeight: 600,
                color: theme => theme.palette.mode === "dark" ? "primary.light" : "primary.main"
              }}>{t("catalog.type")}</TableCell>
              <TableCell sx={{ 
                fontWeight: 600,
                color: theme => theme.palette.mode === "dark" ? "primary.light" : "primary.main"
              }}>{t("catalog.name")}</TableCell>
              <TableCell sx={{ 
                fontWeight: 600,
                color: theme => theme.palette.mode === "dark" ? "primary.light" : "primary.main"
              }}>{t("catalog.description")}</TableCell>
              <TableCell sx={{ 
                fontWeight: 600,
                color: theme => theme.palette.mode === "dark" ? "primary.light" : "primary.main"
              }}>{t("catalog.duration")}</TableCell>
              <TableCell sx={{ 
                fontWeight: 600,
                color: theme => theme.palette.mode === "dark" ? "primary.light" : "primary.main"
              }}>{t("catalog.price")}</TableCell>
              <TableCell sx={{ 
                fontWeight: 600,
                color: theme => theme.palette.mode === "dark" ? "primary.light" : "primary.main"
              }}>{t("catalog.options")}</TableCell>
              <TableCell sx={{ 
                fontWeight: 600,
                color: theme => theme.palette.mode === "dark" ? "primary.light" : "primary.main"
              }}>{t("common.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCatalog.map(item => (
              <TableRow 
                key={item.id}
                sx={{
                  transition: "background-color 0.2s",
                  "&:hover": {
                    backgroundColor: theme => theme.palette.mode === "dark"
                      ? "rgba(144, 202, 249, 0.08)"
                      : "rgba(144, 202, 249, 0.04)"
                  }
                }}
              >
                <TableCell sx={{ fontWeight: 500 }}>{item.type}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{getFormattedDuration(item.duration)}</TableCell>
                <TableCell sx={{ 
                  fontWeight: 500,
                  color: theme => theme.palette.mode === "dark" 
                    ? "success.light" 
                    : "success.dark"
                }}>
                  {price(item.price)}
                </TableCell>
                <TableCell>{item.options}</TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <IconButton 
                      onClick={() => handleOpenForm(item, item.id)}
                      size="small"
                      sx={{ 
                        color: theme => theme.palette.mode === "dark" 
                          ? "primary.light" 
                          : "primary.main"
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton 
                      onClick={() => handleDeleteClick(item.id)}
                      size="small"
                      sx={{ color: "error.main" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ProductForm
        open={formOpen}
        handleClose={handleCloseForm}
        productId={selectedProductId}
        userId={currentUser.uid}
        onSave={handleSaveProduct}
      />

      <CategoryManager
        open={categoryManagerOpen}
        handleClose={() => setCategoryManagerOpen(false)}
      />

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>{t("catalog.deleteConfirmation.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("catalog.deleteConfirmation.message")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            {t("common.cancel")}
          </Button>
          <Button onClick={handleDeleteProduct} color="error">
            {t("common.delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Catalogue;
