import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import EuroIcon from "@mui/icons-material/Euro";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField
} from "@mui/material";

export default function AddDiscountDialog({ onAddDiscount }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [discountName, setDiscountName] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [isPercentage, setIsPercentage] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = e => {
    e.preventDefault();
    if (discountName && discountAmount) {
      const newDiscount = {
        discountName,
        discountAmount: parseFloat(discountAmount),
        isPercentage
      };
      onAddDiscount(newDiscount);
      enqueueSnackbar(t("reservation.discounts.notifications.addSuccess"), {
        variant: "success"
      });
      handleClose();
    } else {
      enqueueSnackbar(t("reservation.discounts.notifications.requiredFields"), {
        variant: "warning"
      });
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleOpen}
        startIcon={<EuroIcon />}
        size="small"
      >
        {t("reservation.discounts.addButton")}
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("reservation.discounts.title")}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              id="discountName"
              label={t("reservation.discounts.name")}
              value={discountName}
              onChange={e => setDiscountName(e.target.value)}
              margin="normal"
              fullWidth
            />
            <TextField
              id="discountAmount"
              label={t("reservation.discounts.amount", {
                unit: isPercentage ? "%" : "€"
              })}
              value={discountAmount}
              onChange={e => setDiscountAmount(e.target.value)}
              margin="normal"
              type="number"
              fullWidth
            />
            <FormControlLabel
              control={
                <Switch
                  checked={isPercentage}
                  onChange={() => setIsPercentage(!isPercentage)}
                  color="primary"
                />
              }
              label={t("reservation.discounts.percentageToggle")}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("common.cancel")}
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {t("common.validate")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
