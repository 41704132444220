import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import jsPDF from "jspdf";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";

import "jspdf-autotable";

import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";

const PDFInvoiceGenerator = ({ reservation, type }) => {
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const [userData, setUserData] = useState(null);
  const [catalogData, setCatalogData] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const userDocRef = doc(db, `users/${currentUser.uid}`);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        setUserData(userDoc.data());
      }
    };

    const fetchCatalogData = async () => {
      const catalogDocRef = doc(db, `users/${currentUser.uid}/catalog/${reservation.catalog_id}`);
      const catalogDoc = await getDoc(catalogDocRef);
      if (catalogDoc.exists()) {
        setCatalogData(catalogDoc.data());
      }
    };

    const fetchClientData = async () => {
      const clientDocRef = doc(db, `users/${currentUser.uid}/customers/${reservation.client_id}`);
      const clientDoc = await getDoc(clientDocRef);
      if (clientDoc.exists()) {
        setClientData(clientDoc.data());
      }
    };

    fetchUserData();
    fetchCatalogData();
    fetchClientData();
  }, [currentUser.uid, reservation.catalog_id, reservation.client_id]);

  const openMenu = event => setMenuAnchor(event.currentTarget);
  const closeMenu = () => setMenuAnchor(null);

  const uploadPDFToStorage = async (pdfBlob, fileName, docNumber) => {
    try {
      const storage = getStorage();
      const pdfPath = `${currentUser.uid}/booking/${reservation.id}/${fileName}`;
      const pdfRef = ref(storage, pdfPath);

      await uploadBytes(pdfRef, pdfBlob);

      const userDocRef = doc(db, `users/${currentUser.uid}`);
      await updateDoc(userDocRef, {
        [type === "facture" ? "invoice" : "quote"]: docNumber + 1
      });

    } catch (error) {
      console.error(t("agenda.documents.pdf.uploadError"), error);
    }
  };

  const generatePDF = async type => {
    if (!userData || !catalogData) return;

    const pdf = new jsPDF();
    const today = new Date().toLocaleDateString("fr-FR");
    let docNumber = type === "facture" ? userData.invoice : userData.quote;
    let version = 1;

    const orderDocRef = doc(db, `users/${currentUser.uid}/orders/${reservation.id}`);
    const orderDoc = await getDoc(orderDocRef);

    if (orderDoc.exists()) {
      const orderData = orderDoc.data();
      docNumber = orderData[`${type}_number`] || (type === "facture" ? userData.invoice : userData.quote);
      version = (orderData[`${type}_version`] || 0) + 1;

      if (!orderData[`${type}_number`]) {
        await updateDoc(orderDocRef, { [`${type}_number`]: docNumber });
      }
      await updateDoc(orderDocRef, { [`${type}_version`]: version });
    } else {
      docNumber = type === "facture" ? userData.invoice : userData.quote;
      await setDoc(orderDocRef, { [`${type}_number`]: docNumber, [`${type}_version`]: version });
    }

    const fileName = `${type === "facture" ? t("agenda.documents.pdf.invoice") : t("agenda.documents.pdf.quote")}_${docNumber}-${version}.pdf`;

    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(20);
    pdf.text(`${type === "facture" ? t("agenda.documents.pdf.invoice") : t("agenda.documents.pdf.quote")} n° ${docNumber}-${version}`, 10, 10);
    pdf.setFontSize(12);
    pdf.setFont("helvetica", "normal");
    pdf.text(`${t("agenda.documents.pdf.date")} : ${today}`, 200, 10, { align: "right" });

    if (userData.logo) {
      const img = new Image();
      img.src = userData.logo;
      const logoWidth = 60;
      const centerX = (210 - logoWidth) / 2;
      pdf.addImage(img, "JPEG", centerX, 20, logoWidth, 0);
    }

    pdf.setFontSize(10);
    let startY = 70;
    if (userData) {
      pdf.text(`${userData.company}`, 10, startY);
      pdf.text(`${userData.address}`, 10, startY + 5);
      pdf.text(`${t("agenda.documents.pdf.siret")} : ${userData.siret}`, 10, startY + 10);
      pdf.text(`${t("agenda.documents.pdf.phone")} : ${userData.tel}`, 10, startY + 15);
      pdf.text(`${t("agenda.documents.pdf.website")} : ${userData.url}`, 10, startY + 20);
    }

    if (clientData) {
      const clientX = 150;
      pdf.text(`${t("agenda.documents.pdf.client")} : ${clientData.firstname || ""}`, clientX, startY);
      pdf.text(`${t("agenda.documents.pdf.address")} : ${clientData.userAddress || ""}`, clientX, startY + 5);
      pdf.text(`${t("agenda.documents.pdf.phone")} : ${clientData.phone || ""}`, clientX, startY + 10);
      pdf.text(`${t("agenda.documents.pdf.email")} : ${clientData.email || ""}`, clientX, startY + 15);
    }

    const totalBaseForDiscounts = parseFloat(catalogData.price) + reservation.fees.reduce((acc, fee) => {
      return acc + (fee.isPercentage ? (catalogData.price * fee.feeAmount) / 100 : fee.feeAmount);
    }, 0);

    const items = [
      {
        description: `${catalogData.name} - ${catalogData.description}`,
        quantity: 1,
        priceHT: parseFloat(catalogData.price),
        priceTTC: parseFloat(catalogData.price)
      },
      ...reservation.fees.map(fee => ({
        description: fee.feeName,
        quantity: 1,
        priceHT: fee.isPercentage
          ? parseFloat((catalogData.price * fee.feeAmount) / 100)
          : parseFloat(fee.feeAmount),
        priceTTC: fee.isPercentage
          ? parseFloat((catalogData.price * fee.feeAmount) / 100)
          : parseFloat(fee.feeAmount)
      })),
      ...reservation.discounts.map(discount => ({
        description: discount.discountName,
        quantity: 1,
        priceHT: discount.isPercentage
          ? -parseFloat((totalBaseForDiscounts * discount.discountAmount) / 100)
          : -parseFloat(discount.discountAmount),
        priceTTC: discount.isPercentage
          ? -parseFloat((totalBaseForDiscounts * discount.discountAmount) / 100)
          : -parseFloat(discount.discountAmount)
      }))
    ];

    const total = items.reduce((acc, item) => acc + item.priceTTC, 0);

    pdf.autoTable({
      startY: startY + 45,
      head: [[
        t("agenda.documents.pdf.description"),
        t("agenda.documents.pdf.quantity"),
        t("agenda.documents.pdf.priceHT"),
        t("agenda.documents.pdf.priceTTC")
      ]],
      body: items.map(item => [
        item.description,
        item.quantity,
        `${item.priceHT.toFixed(2)} €`,
        `${item.priceTTC.toFixed(2)} €`
      ]),
      styles: { font: "helvetica", fontStyle: "normal", fontSize: 10 },
      columnStyles: {
        0: { cellWidth: 115 },
        1: { cellWidth: 15 },
        2: { cellWidth: 30 },
        3: { cellWidth: 30 }
      }
    });

    pdf.text(`${t("agenda.documents.pdf.total")} : ${total.toFixed(2)} €`, 150, pdf.lastAutoTable.finalY + 10);

    pdf.text(t("agenda.documents.pdf.thankYou"), 10, pdf.internal.pageSize.height - 60);

    pdf.autoTable({
      startY: pdf.internal.pageSize.height - 40,
      margin: { left: 10 },
      body: [
        [t("agenda.documents.pdf.paymentMethod"), t("agenda.documents.pdf.bankTransfer")],
        ["IBAN", userData.iban || ""],
        ["BIC", userData.bic || ""]
      ],
      theme: "plain",
      styles: { font: "helvetica", fontStyle: "normal", fontSize: 10 },
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 100 }
      }
    });

    const pdfBlob = pdf.output("blob");
    uploadPDFToStorage(pdfBlob, fileName, docNumber);
    pdf.save(fileName);
  };

  return (
    <>
      <Tooltip title={t("agenda.documents.pdf.manage")}>
        <IconButton onClick={openMenu}>
          <PictureAsPdfIcon color="primary" />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={closeMenu}>
        <MenuItem
          onClick={() => {
            closeMenu();
            generatePDF("devis");
          }}
        >
          {t("agenda.documents.pdf.downloadQuote")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            closeMenu();
            generatePDF("facture");
          }}
        >
          {t("agenda.documents.pdf.generateInvoice")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default PDFInvoiceGenerator;
