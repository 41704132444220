import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import CancelIcon from "@mui/icons-material/Cancel";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, 
  IconButton, MenuItem, TextField, Tooltip } from "@mui/material";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";

import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";

const CancelReservation = ({ reservation }) => {
  const reservationId = reservation.id;
  const { currentUser } = useAuth();
  const [open, setOpen] = useState(false);
  const [rejectionReasons, setRejectionReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  
  useEffect(() => {
    const fetchRejectionReasons = async () => {
      const reasonsCollection = collection(db, `users/${currentUser.uid}/rejectionReasons`);
      const reasonsSnapshot = await getDocs(reasonsCollection);
      const reasonsList = reasonsSnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name
      }));
      setRejectionReasons(reasonsList);
    };

    fetchRejectionReasons();
  }, [currentUser.uid]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCancelOrder = async () => {
    const orderRef = doc(db, `users/${currentUser.uid}/orders/${reservationId}`);
    const currentDate = new Date().toISOString();

    try {
      await updateDoc(orderRef, {
        orderIsCanceled: true,
        rejectionReason: selectedReason || null,
        rejectionDate: currentDate
      });

      enqueueSnackbar(t("agenda.notifications.cancelSuccess"), { variant: "success" });
      setOpen(false);
    } catch (error) {
      enqueueSnackbar(t("agenda.notifications.cancelError") + error.message, { variant: "error" });
    }
  };

  const checkOrderCanceled = (reservation.orderIsCanceled && true === reservation.orderIsCanceled) ? true : false;
  
  return (
    <>
      <Tooltip title={checkOrderCanceled 
        ? t("agenda.cancellation.alreadyCancelled") 
        : t("agenda.actions.cancel")}>
        <IconButton onClick={handleOpen} disabled={checkOrderCanceled}>
          <CancelIcon color={checkOrderCanceled ? "disabled" : "primary"} /> 
        </IconButton>
      </Tooltip>     
      
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("agenda.cancellation.title")}</DialogTitle>
        <DialogContent>
          <TextField
            select
            fullWidth
            label={t("agenda.cancellation.reasonLabel")}
            value={selectedReason}
            onChange={e => setSelectedReason(e.target.value)}
            margin="normal"
          >
            <MenuItem value="">{t("agenda.cancellation.noReason")}</MenuItem>
            {rejectionReasons.map(reason => (
              <MenuItem key={reason.id} value={reason.name}>
                {reason.name}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("common.cancel")}
          </Button>
          <Button onClick={handleCancelOrder} color="error">
            {t("agenda.cancellation.confirmButton")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CancelReservation;
