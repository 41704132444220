import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import EuroIcon from "@mui/icons-material/Euro";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";

export default function AddFeeDialog({ onAddFee }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [feeName, setFeeName] = useState("");
  const [feeAmount, setFeeAmount] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (feeName && feeAmount) {
      const newFee = {
        feeName,
        feeAmount: parseFloat(feeAmount)
      };
      onAddFee(newFee);
      enqueueSnackbar(t("reservation.feesModal.notifications.addSuccess"), {
        variant: "success"
      });
      handleClose();
    } else {
      enqueueSnackbar(t("reservation.feesModal.notifications.requiredFields"), {
        variant: "warning"
      });
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleOpen}
        startIcon={<EuroIcon />}
        size="small"
      >
        {t("reservation.feesModal.addButton")}
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("reservation.feesModal.title")}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              id="feeName"
              label={t("reservation.feesModal.name")}
              value={feeName}
              onChange={e => setFeeName(e.target.value)}
              margin="normal"
              type="text"
              fullWidth
            />
            <TextField
              id="feeAmount"
              label={t("reservation.feesModal.amount")}
              value={feeAmount}
              onChange={e => setFeeAmount(e.target.value)}
              margin="normal"
              type="number"
              fullWidth
              InputProps={{
                endAdornment: <EuroIcon />
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("common.cancel")}
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {t("common.validate")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
